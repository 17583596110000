<template>
  <div class="container-box">
    <div class="main-box">
      <img :src="img" alt="">
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      img: ''
    }
  },
  // 事件处理器
  methods: {
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.img = this.$route.query.img
   },
  // 生命周期-实例挂载后调用
  mounted () {
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.container-box{
  .main-box{
    padding-top: 22px;
    padding-bottom: 60px;
    text-align: center;
    img{
      margin: 0 auto;
      max-width: 1200px;
    }
  }
}
</style>
